import React from "react";

export default function Footer(props) {
  const [currentLanguage, setCurrentLanguage] = React.useState(
    props.footerData.currentLang
  );

  React.useEffect(() => {
    setCurrentLanguage(props.footerData.currentLanguage);
  }, [props]);

  return (
    <footer className="container-fluid d-flex justify-content-between">
      <div className="container-fluid row p-0">
        <div className="col-12  p-0 text-center   center-part  d-flex flex-column justify-content-center align-items-center">
          <div className="d-flex flex-column align-items-center">
            <p className="d-flex ">
              {currentLanguage === "fa" ? (
                <>
                  <span className="text-light">
                    {props.footerData.footerData}{" "}
                  </span>
                  <i
                    className="fa fa-map-marker mx-2 text-light"
                    aria-hidden="true"
                  ></i>
                </>
              ) : (
                <>
                  <i
                    className="fa fa-map-marker mx-2 text-light"
                    aria-hidden="true"
                  ></i>
                  <span className="text-light">
                    {props.footerData.footerData}{" "}
                  </span>
                </>
              )}
            </p>
            <p className="d-flex my-3">
              {currentLanguage === "fa" ? (
                <>
                  <span className="text-light">01132566699</span>
                  <i
                    className="fa fa-phone text-light mx-2"
                    aria-hidden="true"
                  ></i>
                </>
              ) : (
                <>
                  <i
                    className="fa fa-phone text-light mx-2"
                    aria-hidden="true"
                  ></i>
                  <span className="text-light">01132566699</span>
                </>
              )}
            </p>
            <ul className="nav">
              <li className="nav-item">
                <a href="#" className="nav-link text-light">
                  <i className="fa fa-linkedin" aria-hidden="true"></i>
                </a>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link text-light">
                  <i className="fa fa-twitter" aria-hidden="true"></i>
                </a>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link text-light">
                  <i className="fa fa-telegram" aria-hidden="true"></i>
                </a>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link text-light">
                  <i className="fa fa-instagram" aria-hidden="true"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
