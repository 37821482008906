const langData = {
  navLang: {
    enNav: [
      "Main page",
      ["Sub Companies", "Sub Company1", "Sub Company2", "Sub Company3"],
      ["Projects", "Project1", "Project2", "Project3"],
      ["Services", "Sevice1", "Sevice2", "Sevice3"],
      "Invite to cooperate",
      "Contact us",
      "awards",
      "Q&A",
      "Mazandaran prv - Babol city - Noghte AI center",
    ],
    faNav: [
      "صفحه نخست",
      ["شرکت های تابعه", "شرکت1", "شرکت2", "شرکت3"],
      ["پروژه ها", "پروژه1", "پروژه2", "پروژه3"],
      ["خدمات", "خدمت1", "خدمت2", "خدمت3"],
      "دعوت به همکاری",
      "تماس با ما",
      "افتخارات",
      "سوالات متداول",
      "مازندران - بابل - مرکز هوش مصنوعی نقطه",
    ],
  },
  modalData: {
    enModal: [
      "Kered Holding",
      "Contact with us",
      "Mazandaran prv - Babol city -  Noghte AI center",
      "close",
    ],
    faModal: [
      "هولدینگ کرد",
      "با ما در تماس باشید",
      "مازندران - بابل - مرکز هوش مصنوعی نقطه",
      "بستن",
    ],
  },
  servicesData: {
    enServices: {
      title: "Services",
      service1: [
        "Consulting and business",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
        "Lorem ipsum ",
        "Lorem ipsum ",
        "more",
      ],
      service2: [
        "Investmen",
        "Investment on your products",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
        "Lorem ipsum ",
        "Lorem ipsum ",
        // ==============
        "Investor acquisition",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
        "Lorem ipsum ",
        "Lorem ipsum ",
        // ==============
        "more",
      ],
      service3: [
        "Marketing and Brokerage",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
        "Lorem ipsum ",
        "Lorem ipsum ",
        "more",
      ],
      service4: [
        "Customs",
        "Sevices",
        "Buy",
        "Lorem ipsum ",
        "Lorem ipsum ",
        // ===========
        "Clearance",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
        "Lorem ipsum ",
        "Lorem ipsum ",
        // ==========
        "Logistic",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
        "Lorem ipsum ",
        "Lorem ipsum ",
        // ============
        "Exchange",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
        "Lorem ipsum ",
        "Lorem ipsum ",
        // ============
        "more",
      ],
      service5: [
        " ",
        "Construction",
        "",
        "",
        "Land purchase consult",
        "Lorem ipsum ",
        "Lorem ipsum ",
        // ===========
        "Design and architecture",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
        "Lorem ipsum ",
        "Lorem ipsum ",
        // ==========
        "Mechanical and electrical installations",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
        "Lorem ipsum ",
        "Lorem ipsum ",
        // ============
        "Real estate closing process",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
        "Lorem ipsum ",
        "Lorem ipsum ",
        // ============
        "more",
      ],
      service6: [
        "Smart house ",
        "Smart house project",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
        "Lorem ipsum ",
        "Lorem ipsum ",
        // ==============
        "Equipments",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
        "Lorem ipsum ",
        "Lorem ipsum ",
        // ==============
        "more",
      ],
      service7: [
        "Mobile and laptop",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
        "Lorem ipsum ",
        "Lorem ipsum ",
        "more",
      ],
    },
    faServices: {
      title: "حوزه های فعالیت هولدینگ",
      service1: [
        "مشاوره و بازرگانی",
        "لورم  ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از  طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و",
        "لورم ایپسوم ",
        " لورم ایپسوم ",
        "بیشتر",
      ],
      service2: [
        "سرمایه گذاری",
        "سرمایه گذاری روی محصولات شما",
        "لورم  ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از  طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و",
        "لورم ایپسوم  ",
        " لورم ایپسوم ",
        // =================
        "جذب سرمایه گذار",
        "لورم  ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از  طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و",
        "لورم ایپسوم  ",
        " لورم ایپسوم ",
        // ================
        "بیشتر",
      ],
      service3: [
        " بازاریابی و بروکری",
        "لورم  ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از  طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و",
        "لورم ایپسوم ",
        " لورم ایپسوم ",
        "بیشتر",
      ],
      service4: [
        "تشریفات ",
        "گمرکی",
        "خرید",
        "لورم ایپسوم ",
        "لورم ایپسوم ",
        // ===========
        "ترخیص",
        "لورم  ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از  طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و",
        "لورم ایپسوم ",
        "لورم ایپسوم ",
        // ==========
        "لجستیک",
        "لورم  ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از  طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و",
        "لورم ایپسوم ",
        "لورم ایپسوم ",
        // ============
        "صرافی",
        "لورم  ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از  طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و",
        "لورم ایپسوم ",
        "لورم ایپسوم ",
        // ============
        "بیشتر",
      ],
      service5: [
        "ساختمان ",
        "سازی",
        "و",
        "عمران",
        "مشاوره و خرید زمین",
        "لورم ایپسوم ",
        "لورم ایپسوم ",
        // ===========
        "طراحی و معماری سازه",
        "لورم  ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از  طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و",
        "لورم ایپسوم ",
        "لورم ایپسوم ",
        // ==========
        "تاسیسات مکانیکی و برقی",
        "لورم  ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از  طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و",
        "لورم ایپسوم ",
        "لورم ایپسوم ",
        // ============
        "امور اداری تا تحویل کلید",
        "لورم  ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از  طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و",
        "لورم ایپسوم ",
        "لورم ایپسوم ",
        // ============
        "بیشتر",
      ],
      service6: [
        "خانه هوشمند ",
        "پروژه خانه هوشمند ",
        "لورم  ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از  طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و",
        "لورم ایپسوم  ",
        " لورم ایپسوم ",
        // =================
        "فروش تجهیزات",
        "لورم  ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از  طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و",
        "لورم ایپسوم  ",
        " لورم ایپسوم ",
        // ================
        "بیشتر",
      ],
      service7: [
        " موبایل و لپ تاپ ",
        "لورم  ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از  طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و",
        "لورم ایپسوم ",
        " لورم ایپسوم ",
        "بیشتر",
      ],
    },
  },
  aboutusData: {
    aboutusEn: [
      "About us",
      "The group of Kered companies with more than 22 years of experience in the field of international trade, using local experts of the country as well as modern knowledge and technology, has succeeded in obtaining a share of the production, export and import of our beloved country.Over the past ten years, this company has undergone fundamental and important developments and changes, such as applying systemic management, developing strategic plans for itself and all its subsidiaries, diversifying and investing in the capital portfolio in order to increase the profitability of producing diverse products and building complexes in the regions. It has been implemented in different areas of the country in the direction of sustainable development.It is hoped that in line with the cooperation between us, we can provide valuable services to your collection and take a step in the direction of business development and advancing the goals of the industry and pave the way for your success.",
    ],
    aboutusFa: [
      "درباره ما",
      "  مجموعه شرکت های کرد با بیش از 22 سال سابقه فعالیت در عرصه تجارت بین الملل با بکارگیری متخصصین بومی کشور و همچنین دانش و تکنولوژی روز دنیا، موفق گردیده تا سهمی از تولید، صادرات و واردات کشور عزیزمان را بدست آورد.این شرکت طی ده سال گذشته تحولات و تغییرات اساسی و مهمی از جمله، اعمال مدیریت سیستمی، تدوین برنامه‌های راهبردی برای خود و کلیه شرکت‌های زیر مجموعه، تنوع و سرمایه‌گذاری در سبد سرمایه جهت بالا بردن ضریب سود آوری تولید متنوع محصولات و احداث مجتمع‌ها در مناطق مختلف کشور در راستای توسعه پایدار و فراهم و در حوزه های مختلف به اجرای در آورده است. امید است، که در راستای همکاری فی ما بین بتوانیم خدمات ارزنده ای به مجموعه شما ارائه نماییم و در راستای توسعه تجارت و پیشبرد اهداف صنعت گامی برداشته و موفقیت‌تان را راه‌گشا باشیم.",
    ],
  },
  sliderData: {
    EnSlider: {
      title: "Sub Companies",
      s1: [
        "Brand name",
        "Brand descrp",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliquaLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      ],
      s2: [
        "Brand name",
        "Brand descrp",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliquaLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      ],
      s3: [
        "Brand name",
        "Brand descrp",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliquaLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      ],
      s4: [
        "Brand name",
        "Brand descrp",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliquaLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      ],
      s5: [
        "Brand name",
        "Brand descrp",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliquaLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      ],
    },
    FaSlider: {
      title: "شرکت های تابعه",
      s1: [
        "فرانچایز ساب وی موبی دیک",
        "موبی دیک فست فودی با منوی ساندویچ ساب وی",
        "   موبیدیک برند فست فود و بیرون بر با چندین نوع رسپی منحصر بفرد  پرطرفدار و خاص توانسته است جایگاه خود را در صنایع غذایی سالم و سریع بدست آورد. شعب موبیدیک در سراسر کشور یکی از پرفروش ترین های هر منطقه محسوب می شوند.",
      ],
      s2: [
        "نام برند",
        "توضیح برند",
        " لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی   تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد",
      ],
      s3: [
        "نام برند",
        "توضیح برند",
        " لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی   تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد",
      ],
      s4: [
        "نام برند",
        "توضیح برند",
        " لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی   تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد",
      ],
      s5: [
        "نام برند",
        "توضیح برند",
        " لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی   تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد",
      ],
    },
  },
  projectsData: {
    EnProjects: [
      "Projects",
      "all",
      "project1 cat",
      "project1",
      "project2 cat",
      "project2",
      "project3 cat",
      "project3",
      "project4 cat",
      "project4",
      "project5 cat",
      "project5",
      "project6 cat",
      "project6",
    ],
    FaProjects: [
      "پروژه ها",
      "همه",
      "1گروه پروژه",
      "پروژه1",
      "گروه پروژه2",
      "پروژه2",
      "گروه پروژه3",
      "پروژه3",
      "گروه پروژه4",
      "پروژه4",
      "گروه پروژه5",
      "پروژه5",
      "گروه پروژه6",
      "پروژه6",
    ],
  },
  footerAddress: {
    enAddress: "Mazandaran prv - Amol city -  Haraz blv",
    faAddress: "مازندران - آمل - بلوار هراز",
  },
};
export default langData;
