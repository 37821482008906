import Banner from "./components/banner";
import Services from "./components/services";
import AboutUs from "./components/aboutUs";
import Projects from "./components/projects";
import ComponiesSlider from "./components/companiesSlider";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import React from "react";
import langData from "./langugaData";

export default function App() {
    const [currentLanguage, setCurrentLanguage] = React.useState("en");
    const [navLangData, setNavLangData] = React.useState(langData.navLang.enNav);
    const [modalData, setModalData] = React.useState(langData.modalData.enModal);
    const [servicesData, setServicesData] = React.useState(
        langData.servicesData.enServices
    );
    const [aboutusData, setAboutusData] = React.useState(
        langData.aboutusData.aboutusEn
    );
    const [sliderData, setSliderData] = React.useState(
        langData.sliderData.EnSlider
    );
    const [projectData, setProjectData] = React.useState(
        langData.projectsData.EnProjects
    );
    const [footerData, setFooterData] = React.useState(
        langData.footerAddress.enAddress
    );

    // =====================================================================================

    function changeDocLang(lang) {
        document.documentElement.lang = lang;
        setCurrentLanguage(lang);
    }

    // ========================================================================================

    React.useEffect(() => {
        if (document.documentElement.lang.startsWith("en")) {
            document.body.style.direction = "ltr";
        }
        setNavLangData(
            currentLanguage === "fa" ? langData.navLang.faNav : langData.navLang.enNav
        );
        setModalData(
            currentLanguage === "fa"
                ? langData.modalData.faModal
                : langData.modalData.enModal
        );
        setServicesData(
            currentLanguage === "fa"
                ? langData.servicesData.faServices
                : langData.servicesData.enServices
        );
        setAboutusData(
            currentLanguage === "fa"
                ? langData.aboutusData.aboutusFa
                : langData.aboutusData.aboutusEn
        );
        setSliderData(
            currentLanguage === "fa"
                ? langData.sliderData.FaSlider
                : langData.sliderData.EnSlider
        );
        setProjectData(
            currentLanguage === "fa"
                ? langData.projectsData.FaProjects
                : langData.projectsData.EnProjects
        );
        setFooterData(
            currentLanguage === "fa"
                ? langData.footerAddress.faAddress
                : langData.footerAddress.enAddress
        );
    }, [currentLanguage]);

    return (
        <>
            {/* <!--================= Modal =========================--> */}
            <div
                className="modal fade"
                id="moreInformationModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered ">
                    <div className="modal-content">
                        <div className="modal-header p-0">
                            <img
                                className="img-fluid modal-img"
                                src={require("../src/images/ian-taylor-jOqJbvo1P9g-unsplash.webp")}
                                alt="modal-img"
                            />
                        </div>
                        <div className="modal-body">
                            <h1 className="text-center"> {modalData[0]} </h1>
                        </div>
                        <div className="modal-footer d-flex flex-column align-items-end">
                            <div
                                className={`container-fluid  modal-footer-content ${
                                    currentLanguage === "fa" ? "fa" : "en"
                                }`}
                            >
                                <p style={{fontSize: "20px"}}> {modalData[1]} </p>
                                <p className="text-muted mt-3">
                                    keredHolding@gmail.com
                                    <i className="fa fa-envelope mx-2" aria-hidden="true"></i>
                                </p>
                                <p className="text-muted mt-3">
                                    011-32325566
                                    <i className="fa fa-phone mx-2" aria-hidden="true"></i>
                                </p>
                                <p className="text-muted mt-3">
                                    {modalData[2]}
                                    <i className="fa fa-map-marker mx-2 " aria-hidden="true"></i>
                                </p>
                            </div>
                            <div className="container-fluid d-flex justify-content-start">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                >
                                    {modalData[3]}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ============================================================ */}
            <Navbar
                tools={{
                    changeDocLang: changeDocLang,
                    navLangData: navLangData,
                    currentLanguage: currentLanguage,
                }}
            />
            <Banner/>
            <Services
                servicesData={{
                    servicesData: servicesData,
                    currentLang: currentLanguage,
                }}
            />
            <AboutUs
                aboutusData={{
                    aboutusData: aboutusData,
                    currentLanguage: currentLanguage,
                }}
            />
            <ComponiesSlider
                sliderData={{
                    sliderData: sliderData,
                    currentLanguage: currentLanguage,
                }}
            />
            <Projects
                projectData={{
                    projectData: projectData,
                    currentLanguage: currentLanguage,
                }}
            />
            <Footer
                footerData={{
                    footerData: footerData,
                    currentLanguage: currentLanguage,
                }}
            />

        </>
    );
}
