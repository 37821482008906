import React, {useState} from "react";
import {LazyLoadImage} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export default function ComponiesSlider(props) {
    const [currentLanguage, setCurrentLanguage] = React.useState(
        props.sliderData.currentLanguage
    );

    const [innerWidth, setInnerWidth] = useState(window.innerWidth);

    React.useEffect(() => {
        setCurrentLanguage(props.sliderData.currentLanguage);
    }, [props]);

    React.useEffect(() => {

        window.addEventListener("resize", () => {
            setInnerWidth(window.innerWidth);
        });
        return () => {
            window.removeEventListener("resize", () => {
            });
        };
    }, [innerWidth]);

    return (
        <section
            className="containser-fluid companies"
            style={{marginTop: "32px"}}
            id="companies"
        >
            <h1
                className={
                    currentLanguage === "fa" ? "companies-title" : "companies-title en"
                }
            >
                {props.sliderData.sliderData.title}{" "}
            </h1>
            <div
                style={{height: "400px"}}
                id="carouselExampleIndicators"
                className="carousel  slide"
                data-bs-ride="carousel"
            >
                <div className="carousel-inner">
                    <div className="carousel-item  active" data-interval="3000">
                        <LazyLoadImage
                            alt="..."
                            className="slide"
                            src={require("../images/companies/c1.webp")}
                            effect="blur"
                            wrapperProps={{
                                style: {transitionDelay: "1s"},
                            }}
                        />

                        <div className="slide-dscrp-container overflow-hidden">
                            <div className="row h-100">

                                <div
                                    className={`d-flex p-0 ${innerWidth <= 500 ? "flex-column justify-content-center" : (innerWidth > 500 && currentLanguage === 'fa') ? "align-items-center justify-content-end" : "align-items-center justify-content-start"}`}>
                                    <div
                                        className={`logo ${(currentLanguage === 'fa' && innerWidth > 500) ? "order-1" : ""}`}>
                                        <img
                                            className="company-logo"
                                            style={{borderRadius: "50px"}}
                                            src={require("../images/companies/c1-logo.png")}
                                        />
                                    </div>
                                    <div className={`slide-dscrp-title-container en 
                                        ${(innerWidth <= 500) ? "pl-0 text-center" : (innerWidth > 500 && currentLanguage === 'fa') ? "text-right pr-3" : "text-left pl-3"}
                                       `}>
                                        <p className="slide-dscrp-header pt-4 ">
                                            {props.sliderData.sliderData.s1[0]}
                                        </p>
                                        <p className="slide-dscrp-subheader ">
                                            {props.sliderData.sliderData.s1[1]}
                                        </p>
                                    </div>
                                </div>

                                <div
                                    className={`col-12 p-0 slide-dscrp ${
                                        currentLanguage === "fa" ? "text-right" : "text-left"
                                    }`}
                                >
                                    {props.sliderData.sliderData.s1[2]}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item  " data-interval="3000">
                        <LazyLoadImage
                            alt="..."
                            className="slide"
                            src={require("../images/companies/c1.webp")}
                            effect="blur"
                            wrapperProps={{
                                style: {transitionDelay: "1s"},
                            }}
                        />

                        <div className="slide-dscrp-container overflow-hidden">
                            <div className="row h-100">

                                <div
                                    className={`d-flex p-0 ${innerWidth <= 500 ? "flex-column justify-content-center" : (innerWidth > 500 && currentLanguage === 'fa') ? "align-items-center justify-content-end" : "align-items-center justify-content-start"}`}>
                                    <div
                                        className={`logo ${(currentLanguage === 'fa' && innerWidth > 500) ? "order-1" : ""}`}>
                                        <img
                                            className="company-logo"
                                            style={{borderRadius: "50px"}}
                                            src={require("../images/companies/c1-logo.png")}
                                        />
                                    </div>
                                    <div className={`slide-dscrp-title-container en 
                                        ${(innerWidth <= 500) ? "pl-0 text-center" : (innerWidth > 500 && currentLanguage === 'fa') ? "text-right pr-3" : "text-left pl-3"}
                                       `}>
                                        <p className="slide-dscrp-header pt-4 ">
                                            {props.sliderData.sliderData.s1[0]}
                                        </p>
                                        <p className="slide-dscrp-subheader ">
                                            {props.sliderData.sliderData.s1[1]}
                                        </p>
                                    </div>
                                </div>

                                <div
                                    className={`col-12 p-0 slide-dscrp ${
                                        currentLanguage === "fa" ? "text-right" : "text-left"
                                    }`}
                                >
                                    {props.sliderData.sliderData.s1[2]}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item  " data-interval="3000">
                        <LazyLoadImage
                            alt="..."
                            className="slide"
                            src={require("../images/companies/c1.webp")}
                            effect="blur"
                            wrapperProps={{
                                style: {transitionDelay: "1s"},
                            }}
                        />

                        <div className="slide-dscrp-container overflow-hidden">
                            <div className="row h-100">

                                <div
                                    className={`d-flex p-0 ${innerWidth <= 500 ? "flex-column justify-content-center" : (innerWidth > 500 && currentLanguage === 'fa') ? "align-items-center justify-content-end" : "align-items-center justify-content-start"}`}>
                                    <div
                                        className={`logo ${(currentLanguage === 'fa' && innerWidth > 500) ? "order-1" : ""}`}>
                                        <img
                                            className="company-logo"
                                            style={{borderRadius: "50px"}}
                                            src={require("../images/companies/c1-logo.png")}
                                        />
                                    </div>
                                    <div className={`slide-dscrp-title-container en 
                                        ${(innerWidth <= 500) ? "pl-0 text-center" : (innerWidth > 500 && currentLanguage === 'fa') ? "text-right pr-3" : "text-left pl-3"}
                                       `}>
                                        <p className="slide-dscrp-header pt-4 ">
                                            {props.sliderData.sliderData.s1[0]}
                                        </p>
                                        <p className="slide-dscrp-subheader ">
                                            {props.sliderData.sliderData.s1[1]}
                                        </p>
                                    </div>
                                </div>

                                <div
                                    className={`col-12 p-0 slide-dscrp ${
                                        currentLanguage === "fa" ? "text-right" : "text-left"
                                    }`}
                                >
                                    {props.sliderData.sliderData.s1[2]}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <button
                    className="carousel-control-prev btn"
                    type="button"
                    data-target="#carouselExampleIndicators"
                    data-slide="prev"
                >
          <span className="control-container">
            <i className="fa-solid fa-chevron-left text-white"></i>
          </span>
                </button>
                <button
                    className="carousel-control-next btn"
                    type="button"
                    data-target="#carouselExampleIndicators"
                    data-slide="next"
                >
          <span className="control-container">
            <i className="fa-solid fa-chevron-right text-white"></i>
          </span>
                </button>
            </div>
        </section>
    );
}
