import React from "react";
import ReactCountryFlagsSelect from "react-country-flags-select";
import logo from '../images/brand/logo.svg'
let sidenavIsOpen = false;

export default function Navbar(props) {

    const [innerWidth, setInnerWidth] = React.useState(window.innerWidth);
    const [currentLanguage, setCurrentLanguage] = React.useState(
        props.tools.currentLanguage
    );
    const language_ref = React.useRef(null);
    const [selected, setSelected] = React.useState(
        {label: '', countryCode: 'GB'});

    const small_logo = (
        <img
            className="logo-img"
            src={logo}
        />
    );


    // ------------------- current language setter:
    React.useEffect(() => {
        setCurrentLanguage(props.tools.currentLanguage);
        language_ref.current = props.tools.currentLanguage;
    }, [props]);

    // ------------------ sidenav controller:
    React.useEffect(() => {
        if (innerWidth > 1199) {
            document.querySelector(".cover").style.display = "none";
            document.querySelector(".sidenav").style.transform =
                `${currentLanguage === "fa" ? "translateX(300px)" : "translateX(-300px)"}`;
        }
        if (innerWidth <= 1199) {
            // close sidenav when click out of sidenav(retrigger Event listener every time):
            document.querySelector(".cover").addEventListener("click", () => {
                if (sidenavIsOpen) {
                    closeNav();
                }
            });
        }

        window.addEventListener("resize", () => {
            setInnerWidth(window.innerWidth);
        });
        return () => {
            window.removeEventListener("resize", () => {
            });
        };
    }, [innerWidth, currentLanguage]);


    // ----------------- accordion controller:
    React.useEffect(() => {
        var acc = document.getElementsByClassName("accordion");
        var i;
        for (i = 0; i < acc.length; i++) {
            acc[i].addEventListener("click", function () {
                this.classList.toggle("active");
                var panel = this.nextElementSibling;
                if (panel.style.maxHeight) {
                    panel.style.maxHeight = null;
                } else {
                    panel.style.maxHeight = panel.scrollHeight + "px";
                }
            });
        }
    }, []);


    // ----------------- fragment effect:
    React.useEffect(() => {
        document.querySelectorAll(".nav-link").forEach((link) => {
            link.addEventListener("click", (event) => {
                event.preventDefault();
                const sectionID = event.target.dataset.section;
                window.scrollTo(0, document.getElementById(sectionID).offsetTop - 100);
                // Update history with section ID as state
                window.history.pushState({section: sectionID}, null, `#${sectionID}`);
            });
        });

    }, []);


    // =============================== sidenav functions:
    function openNav() {
        sidenavIsOpen = true;
        document.getElementById("mySidenav").style.transform = "translateX(0px)";
        document.body.style.overflow = "hidden";
        document.querySelector(".cover").style.display = "block";
    }

    function closeNav() {
        sidenavIsOpen = false;
        document.getElementById("mySidenav").style.transform =
            `${language_ref.current === 'fa' ? "translateX(300px)" : "translateX(-300px)"}`;
        document.body.style.overflowY = "auto";
        document.body.style.overflowX = "hidden";
        document.querySelector(".cover").style.display = "none";
    }

    function rotateArrow(btnclass) {
        document.querySelector(`.${btnclass} i`).classList.toggle("active");
    }

    // ===============================country select funtion:
    function handleCountrySelect(value) {
        if (value) {
            setSelected(value);
            const lang = value.countryCode === "GB" ? 'en'
                : value.countryCode === 'IR' ? 'fa'
                    : value.countryCode === 'RU' ? 'ru'
                        : value.countryCode === "AE" ? 'ar' : ""
            props.tools.changeDocLang(lang)
        }
    }


    return (
        <>

            <nav
                className={`navbar navbar-expand-xl  main-navbar ${
                    currentLanguage === "fa" ? "fa" : "en"
                }`}
            >
                <div className="container-fluid">
                    <div className="d-flex">
                        <a
                            href="#"
                            data-toggle="modal"
                            data-target="#moreInformationModal"
                            className={
                                currentLanguage === "fa"
                                    ? "contact-us-btn btn fa"
                                    : "contact-us-btn btn en"
                            }
                        >
                            {props.tools.navLangData[5]}
                        </a>
                        {/* =================== */}
                        <div className='country-selector-container'>

                            <ReactCountryFlagsSelect
                                selected={selected}
                                onSelect={handleCountrySelect}
                                customCountries={{
                                    IR: ' ',
                                    RU: ' ',
                                    GB: ' ',
                                    AE: ' ',
                                }}
                                selectWidth={70}
                                selectHeight={43}
                            />
                        </div>
                        {/* =================== */}

                    </div>

                    <div className="collapse navbar-collapse ">
                        <ul
                            style={
                                currentLanguage === "fa"
                                    ? {direction: "rtl"}
                                    : {direction: "ltr"}
                            }
                            className="navbar-nav  mb-2 mb-lg-0"
                        >
                            <li className="nav-item">
                                <a
                                    href="#start"
                                    data-section="start"
                                    style={{marginRight: "23px"}}
                                    className="nav-link  "
                                >
                                    {props.tools.navLangData[0]}
                                </a>
                            </li>
                            <li className="nav-item n3">
                                <a
                                    href="#companies"
                                    data-section="companies"
                                    style={{marginRight: "24px"}}
                                    className="nav-link  "
                                >
                                    {props.tools.navLangData[1][0]}
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    href="#projects"
                                    data-section="projects"
                                    style={{marginRight: "24px"}}
                                    className="nav-link  "
                                >
                                    {props.tools.navLangData[2][0]}
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    href="#service"
                                    data-section="service"
                                    style={{marginRight: "24px"}}
                                    className="nav-link  "
                                >
                                    {props.tools.navLangData[3][0]}
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    href="#"
                                    data-toggle="modal"
                                    data-target="#moreInformationModal"
                                    style={{marginRight: "24px"}}
                                    className="invite-link  "
                                >
                                    {props.tools.navLangData[4]}
                                </a>
                            </li>
                        </ul>
                    </div>

                    <span className={`logo ${currentLanguage === "fa" ? "fa" : "en"}`}>
            {innerWidth > 1199 ? (
                <img
                    className="logo-img"
                    src={logo}
                />
            ) : (
                small_logo
            )}
          </span>

                    {/* ================================= sidebar toggler */}
                    <button
                        className={`btn sidebar-btn ${
                            currentLanguage === "fa" ? "fa" : "en"
                        }`}
                        type="button"
                        onClick={openNav}
                    >
                        <i className="fa-solid fa-bars"></i>
                    </button>
                </div>
            </nav>


            {/* ====================== sidebar ======================== */}

            <div
                id="mySidenav"
                className={`sidenav 
                ${currentLanguage === "fa" ? "fa" : "en"}
                ${innerWidth < 1200 ? "d-block" : "d-none"}
                `}
            >

                <div className="d-flex justify-content-between align-items-center">

                    <div  className='container country-selector-container'>

                        <ReactCountryFlagsSelect
                            selected={selected}
                            onSelect={handleCountrySelect}
                            customCountries={{
                                IR: ' ',
                                RU: ' ',
                                GB: ' ',
                                AE: ' ',
                            }}
                            selectWidth={70}
                            selectHeight={43}

                        />
                    </div>


                    <button
                        type="button"
                        className="btn"
                        style={{marginRight: "30px"}}
                        onClick={closeNav}
                    >
                        <i
                            style={currentLanguage === 'en' ?
                                {rotate: '-180deg'} : {rotate: '0deg'}}
                            className="fa fa-arrow-right text-light"
                            aria-hidden="true"></i>
                    </button>


                </div>

                <div className='sidenav-ul-wrapper'>
                    <ul
                        className="navbar-nav text-right mt-4"
                    >
                        <li className="nav-item">
                            <a
                                href="#start"
                                data-section="start"
                                style={{marginRight: "23px"}}
                                className="nav-link  text-white "
                            >
                                {props.tools.navLangData[0]}
                            </a>
                        </li>
                        <div
                            className={`ml-auto nav-item ac-container mt-3 ${
                                currentLanguage === "fa" ? "fa" : "en"
                            }`}
                        >
                            <button
                                className="btn  accordion companies-accordion-btn"
                                type="button"
                                onClick={() => {
                                    rotateArrow("companies-accordion-btn");
                                }}
                            >
                                <i className="fa-solid fa-chevron-down text-light mx-2"></i>
                                <span
                                    className="text-white pr-2">
                                {props.tools.navLangData[1][0]}
                            </span>
                            </button>
                            <li className="nav-item panel companies-accordion">
                                <ul
                                    style={{marginRight: "25px"}}
                                    className="nav flex-column pb-4"
                                >
                                    <li className="nav-item ">
                                        <a
                                            style={currentLanguage === 'fa' ? {fontWeight: "100"} : null}
                                            href="#companies"
                                            data-section="companies"
                                            className="nav-link text-light"
                                        >
                                            {props.tools.navLangData[1][1]}
                                        </a>
                                    </li>
                                    <li className="nav-item ">
                                        <a
                                            style={currentLanguage === 'fa' ? {fontWeight: "100"} : null}
                                            href="#companies"
                                            data-section="companies"
                                            className="nav-link text-light"
                                        >
                                            {props.tools.navLangData[1][2]}
                                        </a>
                                    </li>
                                    <li className="nav-item ">
                                        <a
                                            style={currentLanguage === 'fa' ? {fontWeight: "100"} : null}
                                            href="#companies"
                                            data-section="companies"
                                            className="nav-link text-light"
                                        >
                                            {props.tools.navLangData[1][3]}
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </div>
                        <div
                            className={`ml-auto nav-item ac-container mt-3 ${
                                currentLanguage === "fa" ? "fa" : "en"
                            }`}
                        >
                            <button
                                className="btn  accordion projects-accordion-btn"
                                type="button"
                                onClick={() => {
                                    rotateArrow("projects-accordion-btn");
                                }}
                            >
                                <i className="fa-solid fa-chevron-down text-light mx-2"></i>
                                <span
                                    className="text-white pr-2">
                                {props.tools.navLangData[2][0]}
                             </span>
                            </button>
                            <li className="nav-item panel projects-accordion">
                                <ul
                                    style={{marginRight: "25px"}}
                                    className="nav flex-column pb-4"
                                >
                                    <li className="nav-item ">
                                        <a
                                            style={currentLanguage === 'fa' ? {fontWeight: "100"} : null}
                                            href="#projects"
                                            data-section="projects"
                                            className="nav-link text-light"
                                        >
                                            {props.tools.navLangData[2][1]}
                                        </a>
                                    </li>
                                    <li className="nav-item ">
                                        <a
                                            style={currentLanguage === 'fa' ? {fontWeight: "100"} : null}
                                            href="#projects"
                                            data-section="projects"
                                            className="nav-link text-light"
                                        >
                                            {props.tools.navLangData[2][2]}
                                        </a>
                                    </li>
                                    <li className="nav-item ">
                                        <a
                                            style={currentLanguage === 'fa' ? {fontWeight: "100"} : null}
                                            href="#projects"
                                            data-section="projects"
                                            className="nav-link text-light"
                                        >
                                            {props.tools.navLangData[2][3]}
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </div>
                        <div
                            className={`ml-auto nav-item ac-container mt-3 ${
                                currentLanguage === "fa" ? "fa" : "en"
                            }`}
                        >
                            <button
                                className="btn  accordion services-accordion-btn"
                                type="button"
                                onClick={() => {
                                    rotateArrow("services-accordion-btn");
                                }}
                            >
                                <i className="fa-solid fa-chevron-down text-light mx-2"></i>
                                <span className="text-white pr-2">
                                 {props.tools.navLangData[3][0]}
                            </span>
                            </button>
                            <li className="nav-item panel services-accordion">
                                <ul
                                    style={{marginRight: "25px"}}
                                    className="nav flex-column pb-4"
                                >
                                    <li className="nav-item ">
                                        <a
                                            style={currentLanguage === 'fa' ? {fontWeight: "100"} : null}
                                            href="#service"
                                            data-section="service"
                                            className="nav-link text-light"
                                        >
                                            {props.tools.navLangData[3][1]}
                                        </a>
                                    </li>
                                    <li className="nav-item ">
                                        <a
                                            style={currentLanguage === 'fa' ? {fontWeight: "100"} : null}
                                            href="#service"
                                            data-section="service"
                                            className="nav-link text-light"
                                        >
                                            {props.tools.navLangData[3][2]}
                                        </a>
                                    </li>
                                    <li className="nav-item ">
                                        <a
                                            style={currentLanguage === 'fa' ? {fontWeight: "100"} : null}
                                            href="#service"
                                            data-section="service"
                                            className="nav-link text-light"
                                        >
                                            {props.tools.navLangData[3][3]}
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </div>
                        <li className="nav-item mt-3">
                            <a
                                href="#"
                                data-toggle="modal"
                                data-target="#moreInformationModal"
                                style={{marginRight: "24px"}}
                                className="invite-link-side  text-white"
                            >
                                {props.tools.navLangData[4]}
                            </a>
                        </li>
                        <li className="nav-item mt-3">
                            <a
                                href="#"
                                style={{marginRight: "24px"}}
                                className="awards-link  text-white"
                            >
                                {props.tools.navLangData[6]}
                            </a>
                        </li>
                        <li className="nav-item mt-3">
                            <a
                                href="#"
                                style={{marginRight: "24px"}}
                                className="qa-link  text-white"
                            >
                                {props.tools.navLangData[7]}
                            </a>
                        </li>
                    </ul>
                </div>


                <div
                    className={
                        currentLanguage === "fa" ? "sidenav-footer" : "sidenav-footer en"
                    }
                >
                    <hr style={{opacity: "1", color: "white", width: "80%"}}/>
                    <p className="d-flex  justify-content-center ">
                        <span className="text-light">{props.tools.navLangData[8]} </span>
                        <i
                            className="fa fa-map-marker mx-1 text-light"
                            aria-hidden="true"
                        ></i>
                    </p>
                    <p className="d-flex justify-content-center mt-2">
                        <span className="text-light">01132566699</span>
                        <i className="fa fa-phone text-light mx-1" aria-hidden="true"></i>
                    </p>
                </div>

            </div>
        </>
    );
}
